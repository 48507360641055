/* General page styling */
.our-services-page {
    font-family: Avenir, sans-serif;
    color: #333;
    padding: 0;
    margin: 0;
  }
  
  /* Hero section */
  .services-hero {
    background-color: #1C1F24;
    color: white;
    padding: 60px 0;
    text-align: center;
  }
  
  .services-hero h1 {
    font-size: 48px;
    color: #ffffff;
  }
  
  /* YNI Offers section */
  .yni-offers {
    padding: 40px;
    text-align: center;
    background-color: #ffffff;
  }
  
  .yni-offers h2 {
    font-size: 36px;
    color: #003366;
    margin-bottom: 10px;
  }
  
  .yni-offers p {
    font-size: 18px;
    line-height: 1.8;
    max-width: 800px;
    margin: 0 auto 20px;
    color: #333;
  }
  
  /* Services breakdown section */
  .services-breakdown {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    background-color: #f5f5f5;
    padding: 50px 20px;
  }
  
  .service-item {
    width: 18%;
    background-color: white;
    padding: 20px;
    text-align: center;
    border-radius: 10px;
    margin-bottom: 30px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .service-item h3 {
    font-size: 24px;
    color: #003366;
    margin-bottom: 15px;
  }
  
  .service-item ul {
    list-style: none;
    padding: 0;
  }
  
  .service-item ul li {
    font-size: 16px;
    line-height: 1.8;
  }
  
  .service-item .service-icon {
    font-size: 40px;
    margin-bottom: 15px;
    color: #003366;
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .services-breakdown {
      flex-direction: column;
      align-items: center;
    }
  
    .service-item {
      width: 80%;
    }
  }
  
  .image-section {
    display: flex;
    justify-content: center;
    padding: 40px;
    background-color: #f5f5f5;
  }
  
  .image-container {
    display: flex;
    justify-content: space-between;
    width: 80%;
  }
  
  .image-container img {
    width: 48%; /* Adjust width to fit the images side by side */
    border-radius: 10px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  @media (max-width: 768px) {
    .image-container {
      flex-direction: column;
      align-items: center;
    }
  
    .image-container img {
      width: 100%;
      margin-bottom: 20px;
    }
  }