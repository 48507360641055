/* General styles for the Join Us page */
.join-us-page {
    font-family: Avenir, sans-serif;
    color: #333;
    margin: 0;
    padding: 0;
  }
  
  /* Hero Section */
  .join-us-hero {
    background-color: #1C1F24; /* Dark background for hero */
    color: white;
    padding: 60px 20px;
    text-align: center;
  }
  
  .join-us-hero h1 {
    font-size: 48px;
    color: white;
    margin-bottom: 10px;
  }
  
  .join-us-hero p {
    font-size: 18px;
    color: #CCCCCC; /* Light grey subtitle */
  }
  
  /* Form Section */
  .join-us-form {
    background-color: #f5f5f5;
    padding: 40px;
    text-align: center;
  }
  
  form {
    background-color: white;
    padding: 30px;
    max-width: 600px;
    margin: 0 auto;
    border-radius: 10px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .form-group {
    margin-bottom: 20px;
    text-align: left;
  }
  
  label {
    display: block;
    font-size: 16px;
    color: #333;
    margin-bottom: 8px;
  }
  
  /* Modify this in your CSS for text boxes */
    input[type="text"],
    input[type="email"],
    textarea {
    width: 100%;
    padding: 10px 0px; /* Ensure padding on both sides */
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    color: #333;
    }
  
  input[type="text"]:focus,
  input[type="email"]:focus,
  textarea:focus {
    outline: none;
    border-color: #003366; /* Dark blue focus outline */
  }
  
  .name-inputs {
    display: flex;
    justify-content: space-between;
  }
  
  .name-inputs input {
    width: 48%; /* Two equal-width input fields */
  }
  
  textarea {
    font-family: Arial, sans-serif;
    resize: vertical;
    height: 100px;
  }
  
  button[type="submit"] {
    background-color: #003366; /* Dark blue */
    color: white;
    padding: 12px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
  }
  
  button[type="submit"]:hover {
    background-color: #002244; /* Slightly darker blue on hover */
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .name-inputs {
      flex-direction: column;
    }
  
    .name-inputs input {
      width: 100%;
      margin-bottom: 10px;
    }
  }
  