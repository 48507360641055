/* General styles */
.meet-the-team-page {
    font-family: Avenir, sans-serif;
    color: #333;
    margin: 0;
    padding: 0;
  }
  
  /* Hero Section */
  .team-hero {
    background-color: #1C1F24; /* Dark background for hero */
    color: white;
    padding: 60px 20px;
    text-align: center;
  }
  
  .team-hero h1 {
    font-size: 48px;
    color: white;
    margin-bottom: 10px;
  }
  
  .team-hero h2 {
    font-size: 24px;
    color: #CCCCCC; /* Light grey subtitle */
  }
  
  /* Team Grid Section */
  .team-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* 3 columns for desktop */
    grid-gap: 30px;
    padding: 50px;
    background-color: #f5f5f5; /* Light grey background */
  }
  
  .team-member {
    background-color: white;
    padding: 20px;
    text-align: center;
    border-radius: 10px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow for each card */
  }
  
  .team-member img {
    width: 100%; /* Make image full width */
    height: auto;
    border-radius: 50%; /* Circle image */
    margin-bottom: 15px;
  }
  
  .team-member h3 {
    font-size: 22px;
    color: #003366; /* Dark blue for name */
    margin-bottom: 10px;
  }
  
  .team-member p {
    font-size: 16px;
    color: #666666; /* Light grey for title */
  }
  
  /* Responsive adjustments for smaller screens */
  @media (max-width: 1024px) {
    .team-grid {
      grid-template-columns: repeat(2, 1fr); /* 2 columns for tablets */
    }
  }
  
  @media (max-width: 768px) {
    .team-grid {
      grid-template-columns: 1fr; /* Stack columns for mobile */
    }
  
    .team-member {
      width: 80%; /* Reduce width for smaller screens */
      margin: 0 auto 30px; /* Center-align the cards */
    }
  }
  