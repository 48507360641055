/* General styling for the page */
.home-page {
    font-family: Avenir, sans-serif;
  }
  
  /* Hero Section */
  .hero {
    background-image: url('/hero_background.jpg');
    background-size: cover;
    background-position: center;
    padding-top: 250px;
    padding-bottom: 100px;
    text-align: center;
  }

  .hero .content {
    background-color: #00336630;
    margin-left: 25%;
    margin-right: 25%;
  }
  
  .hero h1 {
    font-size: 60px;
    color: #ffffff; /* Dark blue for main title */
  }
  
  .hero p {
    font-size: 20px;
    margin-top: -50px;
    margin-bottom: 10px;
    color: #ffffff
  }
  
  .hero .buttons button {
    margin: 10px;
    padding: 15px;
    background-color: #003366;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .hero .buttons button:hover {
    background-color: #002244; /* Slightly darker blue on hover */
  }
  
  /* Who We Are & What We Do Sections */

  .what-we-do {
    padding: 50px;
    background-color: #ffffff; /* White background */
    text-align: left;
  }

  .who-we-are {
    padding: 50px;
    background-color: #f5f5f5; /* White background */
    text-align: left;
  }

  .who-we-are h2, .what-we-do h2 {
    font-size: 36px;
    color: #003366;
    border-bottom: 2px solid #003366;
    margin-bottom: 20px;
  }
  
  .who-we-are .section-content, .what-we-do .section-content {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr; /* 3 columns */
    grid-gap: 20px;
  }
  
  .who-we-are p, .what-we-do p {
    font-size: 18px;
    line-height: 1.6;
    color: #333;
  }
  
  .who-we-are p strong, .what-we-do p strong {
    color: #003366;
  }

/* Brand Carousel */
.brand-carousel {
    background-color: #ffffff;
    padding: 40px;
    text-align: center;
  }
  
  .brand-carousel h2 {
    color: #003366;
    border-bottom: 2px solid #003366;
    font-size: 36px;
    padding-bottom: 20px;
    margin-bottom: 60px;
  }
  
  .brand-logo {
    width: 150px;
    height: auto;
    margin: 0 auto;
    padding: 10px;
  }
  
  /* Footer */
  .footer {
    background-color: #1C1F24;
    padding: 20px;
    text-align: center;
    color: white;
    position: relative;
  }
  
  .footer p {
    margin: 0;
    font-size: 14px;
  }
  
  .footer .social-icons {
    margin-top: 10px;
  }
  
  .footer .social-icons a {
    margin: 0 10px;
  }
  
  .footer .social-icons img {
    width: 24px;
    height: 24px;
    transition: transform 0.3s;
  }
  
  .footer .social-icons img:hover {
    transform: scale(1.2);
  }
  
  