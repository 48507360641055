/* Header styles */
.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background-color: #1C1F24; /* Dark grey/black background color */
    color: white;
  }
  
  .header .logo img {
    width: 100px;
  }
  
  .header nav ul {
    display: flex;
    list-style: none;
  }
  
  .header nav ul li {
    margin-right: 20px;
  }
  
  .header nav ul li a {
    color: white;
    text-decoration: none;
    padding: 10px 15px;
    border-radius: 20px; /* Rounded rectangle effect */
    transition: background-color 0.3s ease;
  }
  
  .header nav ul li a:hover {
    background-color: #003366; /* Blue color for hover effect */
    color: white;
  }

  #logo {
    width: 20%;
  }